import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { func } from "../../Utilities/logFunc";
import { async } from "q";
import { createStripeSubscriptionTransaction } from "../../Utilities/controller";
import PaymentModal from "../HomePage/CoinStore/PaymentModal";
import { toast } from "react-toastify";
import Loader from "../Common/Loader";
// var plp = new plpWidjet();
import { useTranslation } from "react-i18next";
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
const PaymentChooseOption = ({
  showPaymentOption,
  setShowPaymentOption,
  list,
}) => {
  const [show, setShow] = useState(showPaymentOption);
  const [coinDetails, setCoinDetails] = useState({});
  const [stripeToken, setStripeToken] = useState("");
  const [transactionUniqueID, setTransactionUniqueID] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const options = [1, 2];
  const [activeOption, setActiveOption] = useState();
  const handleClose = () => {
    setShowPaymentOption(false);
    setShow(false);
  };

  const handlerPurchase = async () => {
    func("purchase", activeOption);
    // console.log("list*********", list)
    if (!activeOption || activeOption == undefined) {
      setError("Select an option");
      return;
    }

    eventTracking(events.PURCHASE, {
      MG_PaymentMethod: activeOption == 1 ? t("premium.pay_with_mobile") : t("premium.general_payment_methods"),
    });
    
    setLoading(true);
    setShow(false);
    if (activeOption == 2) {
      let payload = {
        subscriptionId: list?.packs?.[0]?._id,
        platform: "WebApp",
      };
      try {
        const res = await createStripeSubscriptionTransaction(payload);
        // console.log("stripe res", res?.data?.params?.clientSecret);

        if (res?.data) {
          func("entereddddddd");

          let coinDetails = {
            _id: list?.packs?.[0]?._id,
            coins: list?.planName,
            amount: list?.packs[0]?.amount,
            currency: {
              symbol: list?.currencyID?.symbol,
            },
          };
          setCoinDetails(coinDetails);
          setStripeToken(res?.data?.params?.clientSecret);
          setTransactionUniqueID(res?.data?.params?.transactionUniqueID);
          setShowPayment(true);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.errors?.[0]?.msg);
      }
    } else {
      // var plp = new window.plpWidjet()
      var today = new Date();
      var data = JSON.stringify({
        config: {
          height: "100%",
        },
        gateway: {
          lang: "en-US",
          publicKey: process.env.REACT_APP_PUBLIC_KEY,
          extId: `D11_${generateRandomCode()}`,
        },
        order: {
          paymentReturnUrl: process.env.REACT_APP_CLIENT_BASE_URL,
          items: [
            {
              packName: list?.packs[0].xsollId,
              planName: list?.packs[0].packName,
              preSelection: list?.packs[0].xsollId,
              quantity: 1,
              amountPerUnit: list?.packs[0].amount,
            },
          ],
        },
      });
      // console.log(data)
      setLoading(false)
      window.location.href = `${
        process.env.REACT_APP_TAN_URL
      }secure/load?p=${encodeURI(data)}`;
    }
  };
  const generateRandomCode = (len) => {
    if (!len) {
      var len = 6;
    }

    var str = "";
    var choices = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    while (str.length < len) {
      str += choices[Math.floor(Math.random() * choices.length)];
    }

    return str;
  };
  if (loading) return <Loader />;
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="authModal_transaction  modal fade "
        id="authModal_transaction"
        centered
      >
       <Modal.Body>
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
            aria-label="Close"
          ></button>
          <div class="row justify-content-center">
            <div class="col-12">
              <h3 class="text-center h2">{t("premium.choose_payment_type")}</h3>
            </div>
            <div className="col-auto mt-5">
              <div
                className="form-check d-flex "
                style={{ alignItems: "center" }}
                onClick={() => setActiveOption(1)}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  id={"gridCheck"}
                  name="option1"
                  // onChange={handleCheckboxChange}
                  checked={activeOption === 1 ? true : false}
                />
                <label className="form-check-terms " for="gridCheck">
                {t("premium.pay_with_mobile")}
                </label>
              </div>
              <div
                className="form-check d-flex mt-2"
                style={{ alignItems: "center" }}
                onClick={() => setActiveOption(2)}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  id="gridCheck2"
                  name="option2"
                  // onClick={handleCheckboxChange}
                  checked={activeOption === 2 ? true : false}
                />
                <label className="form-check-terms " for="gridCheck2">
                {t("premium.general_payment_methods")}
                </label>
              </div>
              {error && (
                <p className="error" style={{ marginBottom: "0" }}>
                  {error}
                </p>
              )}
            </div>
          </div>
          <div class="row justify-content-center">
            <div className="col-md-6 d-flex align-items-center justify-content-center mt-4">
              <button
                className="btn d11-cancel-btn"
                style={{ width: "100%", marginRight: "20px" }}
                onClick={handleClose}
              >
                {t("premium.cancel")}
              </button>
              <button
                className="btn btn-primary "
                style={{ width: "100%" }}
                onClick={handlerPurchase}
              >
                {t("premium.pusrchase")}
              </button>
              {/* {
                                error && <p className='error' style={{ textAlign: 'center' }}>{error}</p>
                            } */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showPayment && (
        <PaymentModal
          transactionUniqueID={transactionUniqueID}
          stripeToken={stripeToken}
          subscriptionDetails={coinDetails}
          showPayment={showPayment}
          setShowPayment={() => setShowPayment(false)}
          setShowPaymentOption={setShowPaymentOption}
        />
      )}
    </>
  );
};

export default PaymentChooseOption;
