import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import moment from "moment";
import { url } from "../../../Utilities/url";
import { func } from "../../../Utilities/logFunc";
import { getHeaderApi, requestContestJoin } from "../../../Utilities/controller";
import PayIcon from '../../../assets/images/pay-icon-svg.svg'
import TeamRegisterModal from "../Tournament/TeamRegisterModal";
import { toast } from "react-toastify";
import { errors } from "../../../Utilities/error";
import D11Gold from '../../../assets/images/D11_Grandmaster_Icon_Gold.png'
import Champion from '../../../assets/images/championIcon.png'
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 



const ContestConfirmationModel = ({
  showContestConfirmationModal,
  setShowContestConfirmationModal,
  tournaments,
  walletData,
  userInGameName,
  setShowJVC,
  setShowPremiumPopupContest
}) => {
  let premiumUser = JSON.parse(localStorage.getItem("premiumUser"))

  // var user_data = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const { t } = useTranslation();

func("contest Username", userInGameName)
  const [show, setShow] = useState(showContestConfirmationModal);
  const [isLoading, setLoading] = useState(false);
  const [showTeamRegisterModal, setShowTeamRegisterModal] = useState(false);
  const [postData, setPostData] = useState(null);
  const [noOfPlayer, setNoOfPlayer] = useState(0);
  const [game, setGame] = useState("");
  const { profileData, updateProfileData } = useProfileDataContext()

  const handleClose = () => {
    setShowContestConfirmationModal((prev) => false);
    setShow(false);
    if(setShowJVC)
    setShowJVC(false)
  };

  const handlerJoinContest = async () => {
    // console.log(profileData)
    const payload = {
      joinedDate: new Date(),
      JoinedTimestamp: new Date().getTime(),
      contest: tournaments?._id,
      inGameUserName: userInGameName,
      user: profileData?.item.user_id,
    };

    eventTracking(events.CONTEST_LIST.JOIN_NOW,{
      MG_GameName:tournaments?.gameAry?.[0]?.name,
      MG_GameType:tournaments?.gameTypeAry?.name,
      MG_GameID:tournaments?.gameAry?.[0]?._id,
      MG_ContestID:tournaments?._id
    });
    

    if (tournaments?.gameTypeAry?.players > 1) {
      setNoOfPlayer(tournaments?.gameTypeAry?.players || 0);
      setPostData(payload);
      // console.log(tournaments)
      setGame(tournaments?.gameAry?.[0]?._id);
      setShowTeamRegisterModal(true);
    } else {
      if(!premiumUser && walletData?.walletBalance < walletData?.toPay){
        toast.error("Insufficient Balance")
        navigate('/home/get-premium')
      }
      else{

      
      setLoading(true);
      try {
        const res = await requestContestJoin(payload);
        if (res?.data?.success) {
          const resProfile = await getHeaderApi();
            updateProfileData(resProfile?.data)
          toast.success(res?.data[0]?.msg)
          func("requestContestJoin", res?.data.data[0].param.item._id);
          setLoading(false);
          setShow(false);
          if(setShowJVC)
    setShowJVC(false)
          setShowContestConfirmationModal((prev) => false);
          navigate("/home/contest-details/" + tournaments?._id);
        } else {
          setLoading(false);
          setShowContestConfirmationModal((prev) => false);
        }
      } catch (error) {
        // console.log("contest confirmation")
        if(error?.response?.data?.errors?.[0]?.data == "events.validation.MAX_FREE_TRANSACTION" || error?.response?.data?.errors?.[0]?.data == "ALREADY_JOINED_MAX_FREE_TRANSACTION" ){
          setShowPremiumPopupContest(true)
        }
      
        
        else{
          if(error?.response?.data?.errors?.[0]?.data == "events.validation.MAX_FREE_TRANSACTION" || error?.response?.data?.errors?.[0]?.data == "ALREADY_JOINED_MAX_FREE_TRANSACTION" ){
            setShowPremiumPopupContest(true)
          }
          else
          toast.error(error?.response?.data?.errors?.[0]?.msg);
          
        }
        setShowContestConfirmationModal((prev) => false);
        setLoading(false);
        
       
      }
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="tournamentModal modal fade"
        id={tournaments?._id}
        centered
      >
        <Modal.Body>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          <div class="row justify-content-center">
            <div class="col-11 px-lg-4">
            {
                premiumUser  ? 
<div className="text-center">
            <img  style={{width:'7.5rem', height:'7.5rem'}} src={localStorage.getItem("planName") == "D11_GRANDMASTER" ? D11Gold : Champion} /> 
            <h2 className='d11-premium mt-2  mb-4'>{t("tournaments.premium")}</h2>
            </div>
            : ""

              }
              <div class="title">
                <h3 class="text-center bodyBoldFont mb-4 pb-3">
                  {tournaments?.title}
                </h3>
              </div>
              <div class=" text-center">
                <div class="d-flex justify-content-between">
                  <h4 class="mb-3">
                    {tournaments?.gameAry[0]?.name} -{" "}
                    {tournaments?.gameTypeAry?.name}
                  </h4>
                  <h4 class="mb-3">
                    <span>
                      {moment(tournaments?.date).format("Do MMM YYYY")}
                    </span>
                    <span>
                      {" "}
                      {moment(tournaments?.dateTime).format("hh:mm A")}
                    </span>
                  </h4>
                </div>
                <div class="bg-warning p-4 text-start">
                  <h3 class="mb-2 bodyBoldFont">{t("contest.confirmation")}</h3>
                  <h4 class="mb-0 d-flex align-items-center">
                  {tournaments?.currency?.[0]?.inCurrency?.code === "D11" ? t("contest.diamond_balance") : t("contest.coin_balance")}  =
                    <span class="icon px-2 ">
                      <img
                        class="icon-20 "
                        src={
                          url?.imageUrl +
                          tournaments?.currency?.[0]?.inCurrency?.img?.default
                        }
                        alt=""
                      />
                    </span>
                    {" "}
                    {walletData?.walletBalance}
                  </h4>
                </div>
                <div class="d-flex flex-column text-start mt-4 pt-2">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between px-0 py-3">
                      <h4 class="bodyBoldFont mb-0">{t("contest.entry_fee")}</h4>
                      {console.log( tournaments?.entryFee)}
                      <h4 class="mb-0">
                        {
                          tournaments?.entryFee > 0 ?  <span class="icon me-2 ">
                          <img
                            src={
                              url.imageUrl +
                              tournaments?.currency[0]?.inCurrency?.img?.default
                            }
                            class="icon-20 "
                            alt=""
                          />
                        </span>
              : ""
                        } 
                       
                        {tournaments?.entryFee > 0 ? tournaments?.entryFee : t("tournaments.free")}
                      </h4>
                    </li>
                    <li class="list-group-item d-flex justify-content-between px-0 py-3">
                      <h4 class="bodyBoldFont mb-0">
                        <span class="icon me-2 ">
                          <img
                            class="icon-24 "
                            src={PayIcon}
                            alt=""
                          />
                        </span>
                        {t("contest.to_pay")}
                      </h4>
                      <h4 class="mb-0">
                        {
                          tournaments?.entryFee > 0 ?
                          <span class="icon me-2">
                            {
                              tournaments?.entryFee > 0 && !premiumUser  &&
                              <img
                              src={
                                url.imageUrl +
                                tournaments?.currency[0]?.inCurrency?.img?.default
                              }
                              class="icon-20 "
                              alt=""
                            />
                            }
                         
                        </span>
                        : ""
                        }
                       
                        {tournaments?.entryFee > 0 && !premiumUser  ? walletData?.toPay : t("tournaments.free")}
                      </h4>
                    </li>
                    {
                      premiumUser && tournaments?.entryFee > 0 && (
                        <li class="list-group-item d-flex justify-content-between px-0 py-3">
                      <h4 class="bodyBoldFont mb-0">
                        
                      {/* {tournaments?.currency?.[0]?.inCurrency?.code === "D11" ? "Diamond" : "Coin"} Saved */}
                      {t('tournaments.saved', {coin:`${tournaments?.currency?.[0]?.inCurrency?.code}`})}

                      </h4>
                      <h4 class="mb-0">
                        {
                          tournaments?.entryFee > 0  ?
                          <span class="icon me-2">
                          <img
                            src={
                              url.imageUrl +
                              tournaments?.currency[0]?.inCurrency?.img?.default
                            }
                            class="icon-20"
                            alt=""
                          />
                        </span>
                        : ""
                        }
                       
                        {tournaments?.entryFee > 0    ? walletData?.toPay : t("tournaments.free")}
                      </h4>
                    </li>
                      )
                    }
                  </ul>
                </div>
                <div class="row justify-content-center mt-4 pt-2">
                  <div class="col-lg-7">
                    <div class="d-grid">
                      <button
                        class="btn btn-primary btn-arrow"
                        onClick={handlerJoinContest}
                      >
                        {tournaments?.isJoined ? t("tournaments.joined") : t("contest.join_contest")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showTeamRegisterModal && (
        <TeamRegisterModal
          showTeamRegisterModal={showTeamRegisterModal}
          setShowTeamRegisterModal={setShowTeamRegisterModal}
          postData={postData}
          type={"contest"}
          noOfPlayer={noOfPlayer}
          setShowTournamentConfirmationModal={setShowContestConfirmationModal}
          game={game}
          setShowPremiumPopupTournament={setShowPremiumPopupContest}
          userInGameName={userInGameName}
        />
      )}
    </>
  );
};

export default ContestConfirmationModel;
