import React, { useState } from "react";
// import Background from "./images/header_bg.png"
import slice4 from "./images/header.png"
import CardSection from "./CardSection";
import LandingPage from "./LandingPage";
import { Link } from "react-router-dom";
import OurPlans from "./images/OurPlans.png";
import d111 from "./images/d11_1.png";
import d112 from "./images/d11_2.png";
import d113 from "./images/d11_3.png";
import { routes } from "../../Utilities/routesFb";
import Error from "../Common/Error";
import Loader from "../Common/Loader";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { createNewsLetter } from "../../Utilities/controller";

const LandingMain = () => {
  const [active, setActive] = useState(5);
  const [codeVal, setCodeVal] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handlerSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!codeVal || codeVal.length <= 0) {
      setError("Please enter your email id");
    } else {
      if (ValidateEmail(codeVal)) {
        setLoading(true);
        try {
          const res = await createNewsLetter({ email: codeVal?.toLowerCase() });
          if (res?.data?.success) {
            setCodeVal("");
            toast.success("Newsletter submitted successfully");
          } else {
            setError("Please enter valid email id");
          }
          setLoading(false);
        } catch (error) {
          setError(error?.response?.data?.errors?.[0]?.msg);
          setLoading(false);
        }
      } else {
        setError("Please enter valid email id");
      }
    }
  };

  function ValidateEmail(input) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }

  // if (isLoading) return <Loader />;
  return (
    <LandingPage active={active} setActive={setActive}>
      <ToastContainer />
      <main class="d-grid align-content-center">
        <div>
          <div class="hero_section hero_section_main">
              {/* <img src={Background} class="mainhome_image card-img" alt="..." /> */}
              <div class="hero_section--card hero_section_main">
                <div class="container" >
                    <div class="row g-0 d-flex align-items-center row-card homecard ">
                      <div class="col-lg-7 col-md-7 col-sm-7 col-12">
                          <div class="card-body">
                            <h1 class="mb-3">
                              WELCOME TO D11 GAMING.
                              <br />
                              YOUR GAMING HQ.
                            </h1>
                            <p>
                              Ready to take your gaming to the next level? D11 is the
                              ultimate destination for competitive esports
                              <br />
                              gaming in the Middle East. Come join us for daily tournaments,
                              meet other gamers, and show off your
                              <br />
                              skills for a chance to win prizes and get rewarded! So, what
                              are you waiting for? Let’s get in the game
                              <br />
                              and level up!
                            </p>
                            <div class="col d-flex mb-5 let-play">
                              <Link
                                to="/login"
                                class="btn1 btn1-primary margin-right-sm "
                                style={{
                                  color: "#fff",
                                  border: "1px solid #fff",
                                  textDecoration: "none",
                                  // padding: '10px'
                                }}
                              >
                                Let's Play
                              </Link>
                            </div>
                          </div>
                      </div>
                      <div class="col-lg-5 col-md-5 col-sm-5 col-12">
                          <div class="hero_image"><img src={slice4} className="homecard-image card-img" alt="..."/></div>
                      </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </main>
      <div class="container top-and-bottom"></div>
      <CardSection />
      <div class="container top-and-bottom"></div>
      <div class="container">
        <section class="section-how">
          <div class="row flex-column" style={{ alignItems: "center" }}>
            <div class="col d-flex justify-content-center my-4">
              <h1 class="heading-primary mt-3 mb-3">now playing.</h1>
            </div>
            <div class="col-8 d-flex justify-content-center">
              <p class="text-center">
              Check out our latest tournaments and don't miss your change to participate!
              </p>
            </div>
            <div class="col d-flex justify-content-center mb-5">
              <Link
                to={routes.login}
                class="btn1 btn1-primary margin-right-sm mt-5 hover-primary"
                style={{
                  color: "#fff",
                  border: "1px solid #fff",
                  textDecoration: "none",
                }}
              >
                View All Tournaments
              </Link>
            </div>
          </div>
        </section>
      </div>
      <div class="container top-and-bottom"></div>
      <div class="container">
        <section class="section-how">
          <div class="row flex-column" style={{ alignItems: "center" }}>
            <div class="col d-flex justify-content-center my-4">
              <h1 class="heading-primary mt-3 mb-3">our plans. simple.</h1>
            </div>
            <div class="col-8 d-flex justify-content-center ">
              <p class="text-center">
                Register for FREE today and start winning or subscribe to our
                Premium packages to win up to twice the prizes and lots of other
                premium perks!
              </p>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <img
                  src={d111}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <img
                  src={d112}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <img
                  src={d113}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
            <div class="col d-flex justify-content-center mb-5 ">
              <Link
                to={`/login`}
                class="btn1 btn1-primary margin-right-sm mt-5 hover-primary"
                style={{
                  color: "#fff",
                  border: "1px solid #fff",
                  textDecoration: "none",
                }}
              >
                Discover D11 Premium
              </Link>
            </div>
          </div>
        </section>
      </div>
      <div class="">
        <section class="section-subscribe">
          <div class="container d-flex align-items-center justify-content-center flex-column">
            <div class="row flex-column">
              <div class="col-12 d-flex justify-content-center my-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewbox="0 0 54 54"
                  fill="none"
                >
                  <g clip-path="url(#clip0_3879_96839)">
                    <path
                      d="M48.5312 7.51562H5.46875C2.72455 7.51562 0.5 9.74018 0.5 12.4844V42.2969C0.5 45.0411 2.72455 47.2656 5.46875 47.2656H48.5312C51.2754 47.2656 53.5 45.0411 53.5 42.2969V12.4844C53.5 9.74018 51.2754 7.51562 48.5312 7.51562ZM48.5312 12.4844V16.7083C46.2102 18.5984 42.51 21.5374 34.5994 27.7317C32.8561 29.103 29.4028 32.3974 27 32.359C24.5976 32.3978 21.1432 29.1025 19.4006 27.7317C11.4913 21.5384 7.79009 18.5987 5.46875 16.7083V12.4844H48.5312ZM5.46875 42.2969V23.0842C7.84071 24.9734 11.2044 27.6246 16.3315 31.6393C18.594 33.4203 22.5563 37.3519 27 37.328C31.4219 37.3519 35.3339 33.4773 37.6676 31.6401C42.7945 27.6255 46.1592 24.9736 48.5312 23.0843V42.2969H5.46875Z"
                      fill="#FFBD59"
                    ></path>
                  </g>
                  <defs>
                    <clippath id="clip0_3879_96839">
                      <rect
                        width="53"
                        height="53"
                        fill="white"
                        transform="translate(0.5 0.890625)"
                      ></rect>
                    </clippath>
                  </defs>
                </svg>
              </div>
              <div class="col-12">
                <h1 class="heading-primary text-center">
                  Subscribe to our newsletter.
                </h1>
              </div>
              <div class="col-12 mt-2">
                <p class="subheading text-center mb-5">
                  Don't miss new updates on your email
                </p>
              </div>
              <div class="col-12">
                <p className="error">{error && error}</p>
              </div>
              <div class="col-12">
                <form className="row justify-content-center">
                  <div class="input-group subscribe_input mb-5">
                    <input
                      type="email"
                      class="form-control text-white text-lowercase"
                      placeholder="Enter your email"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={(e) => setCodeVal(e?.target?.value?.toLowerCase())}
                      value={codeVal}
                    />{" "}
                    <span
                      class="input-group-text"
                      id="basic-addon2"
                      onClick={(e) => handlerSubmit(e)}
                    >
                      <strong>Subscribe</strong>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </LandingPage>
  );
};

export default LandingMain;
