import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { json, useNavigate } from "react-router";

import { func } from "../../../Utilities/logFunc";
import { blogList } from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";
import { routes } from "../../../Utilities/routesFb";
import { Link } from "react-router-dom";
import { logout } from "../../../Utilities/logout";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const News = ({ handleClickBlog }) => {
  let [newsData, setNewsData] = useState([]);
  const navigate = useNavigate();
  const {t} = useTranslation()

  const fetchBlogs = async (totalCount) => {
    try {
      let data = {
        skip: 0,
        limit: 4,
        sort: "desc",
        sortBy: "createdAt",
      };

      const res = await blogList(data);
      if (res?.data?.list) {
        setNewsData(res?.data?.list);
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
      logout()
      // toast.error(error?.response?.data?.errors[0]?.msg);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <>
      {newsData?.length > 0 && (
        <div class="col-12" >
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <h2 class="h3 bodyBoldFont mb-0">{t('woe.esports_news')}</h2>
            <Link
            to={`/home/world-of-esports/${routes.news}`}
          //  onClick={() => navigate()}
           >
             {t('woe.view_all')}
            </Link>
          </div>
          <div class="row g-20">
            {newsData.length > 0 &&
              newsData.map((data, indp) => (
                <div class="col-lg-3 col-md-4 col-6">
                  <div class="card newsCard">
                    <div class="card-body" style={{padding:'20px'}}>
                      <div class="card-img" style={{height:'10rem'}}>
                        <img
                          class="rounded-1 w-100"
                          style={{height:'100%', objectFit:'cover'}}
                          src={url?.imageUrl + data?.img?.default}
                          alt=""
                        />
                      </div>
                      <div class="card-content mt-2">
                        <div class="d-flex align-items-end justify-content-between">
                          <div className="row w-100">
                            <div className="col-sm-12">
                              <div class="info pt-2" >
                                <h6 class="mb-1 bodyBoldFont fs-4">
                                  {
                                    data?.title.length > 30 ? 
                                  <marquee>{data?.title}</marquee>
                                  : data?.title
}
                                </h6>
                              </div>
                            </div>
                            <div className="col-sm-9 pe-0">
                              <div class="info">
                                <h6 class="mb-0 fs-6 bodyNormalFont text-body newsDescription text-ellipsis_contest" >
                                  {/* {data?.shortDescription} */}
                                  {
                                  // data?.shortDescription?.length > 40
                                  //   ? data?.shortDescription?.substring(
                                  //       0,
                                  //       40 - 3
                                  //     ) + "..."
                                  //   :
                                     data?.shortDescription}
                                </h6>
                              </div>
                            </div>
                            <div className="col-sm-3 ps-sm-0 pe-sm-0 pt-sm-0 mt-3">
                              <span
                                
                                onClick={() => {
                                  navigate(
                                    "/home/world-of-esports/news-details",
                                    {state: data}
                                  );
                                }}
                                className="text-danger bodyBoldFont cursor" style={{fontSize:'.875rem'}}
                              >
                                {t('woe.read_more')}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default News;
